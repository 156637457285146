/**
 * Smooth scrolling inside an element
 */
 #top,
 #about-us,
 #services,
 #contact-us {
     scroll-behavior: smooth;
 }
 
 /**
  * Smooth scrolling on the whole document
  */
 html {
     scroll-behavior: smooth;
 }
 
 .goToTopHidden {
     display: none;
 }
 
 .goTop {
     display: block;
     position: fixed;
     right: 2%;
     bottom: 2%;
     background: rgba(255,255,255,.3);
     padding: 4px 8px 0;
     border-radius: 8px;
     cursor: pointer;
 }